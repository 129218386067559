#user-table {
  td:nth-child(2),
  td:nth-child(3),
  td:nth-child(8) {
    text-align: center;
  }
}

#confirm-pass {
  .was-validated .form-control:invalid,
  .form-control.is-invalid {
    border-color: var(--bs-form-invalid-border-color);
    // padding-right: calc(1.5em + 0.94rem);
    background-image: none;
    background-repeat: no-repeat;
    background-position: 0;
    // background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
  }

  .invalid-feedback {
    display: none;
  }
}
