//
// _menu.scss
//

.metismenu {
  margin: 0;

  li {
    display: block;
    width: 100%;
  }

  .mm-collapse {
    display: none;

    &:not(.mm-show) {
      display: none;
    }

    &.mm-show {
      display: block;
    }
  }

  .mm-collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition-timing-function: ease;
    transition-duration: 0.35s;
    transition-property: height, visibility;
  }
}

.vertical-menu {
  width: $sidebar-width;
  z-index: 1005;
  background: var(--#{$prefix}sidebar-bg);
  bottom: 0;
  margin-top: 0;
  position: fixed;
  top: 0;
  box-shadow: $box-shadow;

  .vertical-menu-btn {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
  }
}

.main-content {
  margin-left: $sidebar-width;
  overflow: hidden;

  .content {
    padding: 0 15px 10px 15px;
    margin-top: $header-height;
  }
}

.sidebar-menu-scroll {
  height: calc(100% - #{$header-height});
  margin-top: $header-height;
}

#sidebar-menu {
  padding: 10px 0 30px 0;

  .mm-active {
    > .has-arrow {
      &:after {
        transform: rotate(-180deg);
      }
    }
  }

  .has-arrow {
    &:after {
      content: "\F0140";
      font-family: "Material Design Icons";
      display: block;
      float: right;
      transition: transform 0.2s;
      font-size: 1rem;
    }
  }

  ul {
    li {
      a {
        display: block;
        padding: 0.6rem 1.1rem;
        color: var(--#{$prefix}sidebar-menu-item-color);
        position: relative;
        font-size: 15px;
        font-weight: 500;
        transition: all 0.4s;
        margin: 0 10px;
        border-radius: 3px;

        i {
          display: inline-block;
          min-width: 1.5rem;
          padding-bottom: 0.125em;
          font-size: 1.05rem;
          line-height: 1.40625rem;
          vertical-align: middle;
          color: var(--#{$prefix}sidebar-menu-item-icon-color);
          transition: all 0.4s;
        }

        &:hover {
          color: var(--#{$prefix}sidebar-menu-item-hover-color);

          i {
            color: var(--#{$prefix}sidebar-menu-item-hover-color);
          }
        }
      }

      .badge {
        margin-top: 4px;
      }

      ul.sub-menu {
        padding: 0;

        li {
          a {
            padding: 0.4rem 1.5rem 0.4rem 3.5rem;
            font-weight: 400;
            color: $sidebar-menu-sub-item-color;
            margin: 0;
            background-color: transparent;
            font-size: 14.4px;

            &:hover {
              color: var(--#{$prefix}sidebar-menu-item-hover-color);
            }
          }

          ul.sub-menu {
            padding: 0;

            li {
              a {
                padding: 0.4rem 1.5rem 0.4rem 4.5rem;
                font-size: 13.5px;
              }
            }
          }
        }
      }
    }
  }
}

.menu-title {
  padding: 12px 20px !important;
  letter-spacing: 0.05em;
  pointer-events: none;
  cursor: default;
  font-size: 11px;
  text-transform: uppercase;
  color: var(--#{$prefix}sidebar-menu-item-icon-color);
  font-weight: $font-weight-semibold;
}

.mm-active {
  color: var(--#{$prefix}sidebar-menu-item-active-color) !important;

  > a {
    background-color: var(--#{$prefix}sidebar-menu-item-active-bg-color);
    color: var(--#{$prefix}sidebar-menu-item-active-color) !important;
    > i {
      color: var(--#{$prefix}sidebar-menu-item-active-color) !important;
    }
  }

  .active {
    color: var(--#{$prefix}sidebar-menu-item-active-color) !important;

    i {
      color: var(--#{$prefix}sidebar-menu-item-active-color) !important;
    }
  }
  > i {
    color: var(--#{$prefix}sidebar-menu-item-active-color) !important;
  }
}

@media (max-width: 992px) {
  .vertical-menu {
    display: none;
    top: $header-height;

    .sidebar-menu-scroll {
      height: 100%;
      margin-top: 0;
    }

    .navbar-brand-box,
    .vertical-menu-btn {
      display: none;
    }
  }

  .main-content {
    margin-left: 0 !important;
  }

  body.sidebar-enable {
    .vertical-menu {
      display: block;
    }
  }
}

// Enlarge menu
body[data-sidebar-size="sm"] {
  @media (min-width: 769px) {
    min-height: 1450px;
  }

  .main-content {
    margin-left: $sidebar-collapsed-width;
  }

  .navbar-brand-box {
    width: $sidebar-collapsed-width !important;
  }

  .logo {
    span.logo-lg {
      display: none;
    }

    span.logo-sm {
      display: block;
    }
  }

  // Side menu
  .vertical-menu {
    position: absolute;
    width: $sidebar-collapsed-width !important;
    z-index: 1001;

    .simplebar-mask,
    .simplebar-content-wrapper {
      overflow: visible !important;
    }

    .simplebar-scrollbar,
    .vertical-menu-btn {
      display: none !important;
    }

    .simplebar-offset {
      bottom: 0 !important;
    }

    // Sidebar Menu
    #sidebar-menu {
      .menu-title,
      .badge,
      .collapse.in {
        display: none !important;
      }

      .nav.collapse {
        height: inherit !important;
      }

      .has-arrow {
        &:after {
          display: none;
        }
      }

      > ul {
        > li {
          position: relative;
          white-space: nowrap;

          > a {
            padding: 15px 0;
            transition: none;

            &:hover,
            &:active,
            &:focus {
              color: var(--#{$prefix}sidebar-menu-item-hover-color);
            }

            i {
              font-size: 1.3rem;
              text-align: center;
              min-width: 50px;
              padding-bottom: 0;
            }

            span {
              display: none;
              padding-left: 25px;
            }
          }

          &:hover {
            > a {
              position: relative;
              width: calc(180px + #{$sidebar-collapsed-width});
              color: $primary;
              background-color: var(
                --#{$prefix}sidebar-menu-item-active-bg-color
              );
              transition: none;
              box-shadow: inset 0 3px 10px 0 rgba(154, 161, 171, 0.2);

              i {
                color: $primary;
              }

              span {
                display: inline;
              }
            }

            > ul {
              display: block;
              left: $sidebar-collapsed-width;
              position: absolute;
              width: 190px;
              height: auto !important;
              box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, 0.1);

              ul {
                box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, 0.1);
              }

              a {
                box-shadow: none;
                padding: 8px 20px;
                position: relative;
                width: 190px;
                z-index: 6;
                color: var(--#{$prefix}sidebar-menu-sub-item-color);

                &:hover {
                  color: var(--#{$prefix}sidebar-menu-item-hover-color);
                }
              }
            }
          }
        }

        ul {
          padding: 5px 0;
          z-index: 9999;
          display: none;
          background-color: var(--#{$prefix}sidebar-bg);

          li {
            &:hover {
              > ul {
                display: block;
                left: 190px;
                height: auto !important;
                margin-top: -36px;
                position: absolute;
                width: 190px;
              }
            }

            > a {
              span.pull-right {
                position: absolute;
                right: 20px;
                top: 12px;
                transform: rotate(270deg);
              }
            }
          }

          li.active {
            a {
              color: $gray-100;
            }
          }
        }
      }
    }
  }
}

body[data-sidebar="dark"] {
  .vertical-menu {
    background-color: var(--#{$prefix}sidebar-dark-bg);

    .navbar-brand-box {
      background-color: var(--#{$prefix}sidebar-dark-bg);
    }
  }

  .vertical-menu-btn {
    color: var(--#{$prefix}sidebar-dark-menu-item-color);
  }

  #sidebar-menu {
    ul {
      li {
        a {
          color: var(--#{$prefix}sidebar-dark-menu-item-color);

          i {
            color: var(--#{$prefix}sidebar-dark-menu-item-icon-color);
          }

          &:hover {
            color: var(--#{$prefix}sidebar-dark-menu-item-hover-color);

            i {
              color: var(--#{$prefix}sidebar-dark-menu-item-hover-color);
            }
          }
        }

        ul.sub-menu {
          li {
            a {
              color: var(--#{$prefix}sidebar-dark-menu-sub-item-color);

              &:hover {
                color: var(--#{$prefix}sidebar-dark-menu-item-hover-color);
              }
            }
          }
        }
      }
    }
  }

  // Enlarge menu
  &[data-sidebar-size="sm"] {
    min-height: 1300px;
    // Side menu
    .vertical-menu {
      // Sidebar Menu
      #sidebar-menu {
        > ul {
          > li {
            &:hover {
              > a {
                background-color: var(--#{$prefix}sidebar-dark-bg) !important;
                color: var(--#{$prefix}sidebar-dark-menu-item-hover-color);
                i {
                  color: var(--#{$prefix}sidebar-dark-menu-item-hover-color);
                }
              }

              > ul {
                a {
                  color: var(--#{$prefix}sidebar-dark-menu-sub-item-color);
                  &:hover {
                    color: var(--#{$prefix}sidebar-dark-menu-item-hover-color);
                  }
                }
              }
            }
          }

          // ul {
          //     background-color: var(--#{$prefix}sidebar-dark-bg) !important;

          // }
        }

        ul {
          li {
            // &:hover{
            //     >a{
            //         background-color: var(--#{$prefix}sidebar-dark-bg) !important;
            //     }
            // }
            &.mm-active .active {
              color: var(--#{$prefix}sidebar-menu-item-active-color) !important;
              i {
                color: var(
                  --#{$prefix}sidebar-menu-item-active-color
                ) !important;
              }
            }
          }
        }
      }

      .sub-menu {
        li {
          &:hover {
            a {
              color: var(--#{$prefix}sidebar-menu-sub-item-color) !important;
            }
          }
        }
        .mm-active {
          color: var(--#{$prefix}sidebar-menu-item-active-color) !important;
        }
      }
    }
  }
  .mm-active {
    color: var(--#{$prefix}sidebar-dark-menu-item-active-color) !important;
    > i {
      color: var(--#{$prefix}sidebar-dark-menu-item-active-color) !important;
    }
    > a {
      background-color: var(--#{$prefix}sidebar-dark-bg);
      color: var(--#{$prefix}sidebar-dark-menu-item-active-color) !important;
      > i {
        color: var(--#{$prefix}sidebar-dark-menu-item-active-color) !important;
      }
    }
    .active {
      color: var(--#{$prefix}sidebar-dark-menu-item-active-color) !important;

      i {
        color: var(--#{$prefix}sidebar-dark-menu-item-active-color) !important;
      }
    }
  }

  .menu-title {
    color: var(--#{$prefix}sidebar-dark-menu-item-icon-color);
  }
}

body[data-layout="horizontal"] {
  .main-content {
    margin-left: 0 !important;
  }
}

// Compact Sidebar

body[data-sidebar-size="small"] {
  .navbar-brand-box {
    width: $sidebar-width-sm;
    @media (max-width: 991.98px) {
      width: auto;
    }
  }
  .vertical-menu {
    width: $sidebar-width-sm;
    text-align: center;

    .has-arrow:after,
    .badge {
      display: none !important;
    }

    .vertical-menu-btn {
      display: none;
    }
  }

  .main-content {
    margin-left: $sidebar-width-sm;
  }
  #page-topbar,
  .footer {
    left: $sidebar-width-sm;
    @media (max-width: 991.98px) {
      left: 0;
    }
  }

  #sidebar-menu {
    ul li {
      &.menu-title {
        background-color: var(--#{$prefix}sidebar-bg);
      }
      a {
        i {
          display: block;
        }
      }
      ul.sub-menu {
        li {
          a {
            padding-left: 1.5rem;
          }

          ul.sub-menu li a {
            padding-left: 1.5rem;
          }
        }
      }
    }
  }

  &[data-sidebar="dark"] {
    #sidebar-menu {
      ul li {
        &.menu-title {
          background-color: var(--#{$prefix}sidebar-bg-dark);
        }
      }
    }
  }

  .navbar-header .vertical-menu-btn {
    display: block;
  }
  body[data-sidebar-size="sm"] {
    .navbar-header .vertical-menu-btn {
      display: none;
    }
  }
  body[data-sidebar-size="sm"] {
    .main-content {
      margin-left: $sidebar-collapsed-width;
    }
    .vertical-menu {
      #sidebar-menu {
        text-align: left;
        > ul {
          > li {
            > a {
              i {
                display: inline-block;
              }
            }
          }
        }
      }
    }
    #page-topbar,
    .footer {
      left: $sidebar-collapsed-width;
    }
  }
}

// colored sidebar

body[data-sidebar="colored"] {
  .vertical-menu {
    background-color: $primary;

    .vertical-menu-btn {
      color: rgba($white, 0.8);
    }
  }
  .navbar-brand-box {
    background-color: $primary;
    .logo-dark {
      display: none;
    }
    .logo-light {
      display: block;
    }
  }

  .mm-active {
    color: $white !important;
    > a {
      background-color: rgba($white, 0.1);
      color: $white !important;
      > i {
        color: $white !important;
      }
    }
    > i,
    .active {
      color: $white !important;
    }
  }

  #sidebar-menu {
    ul {
      li {
        &.menu-title {
          background-color: rgba($white, 0.08);
          color: rgba($white, 0.7);
        }

        a {
          color: rgba($white, 0.7);
          i {
            color: rgba($white, 0.7);
          }
          &.waves-effect {
            .waves-ripple {
              background: rgba($white, 0.1);
            }
          }
        }

        ul.sub-menu {
          li {
            a {
              color: rgba($white, 0.6);
            }
          }
        }

        .badge {
          &.badge-primary {
            background-color: $white;
            color: $primary;
          }
        }
      }
    }
  }

  &[data-sidebar-size="sm"] {
    .vertical-menu {
      #sidebar-menu {
        > ul {
          > li {
            &:hover > a {
              background-color: lighten($primary, 2%);
              color: $white;
              i {
                color: $white;
              }
            }
          }
        }

        ul {
          li {
            &.mm-active {
              color: var(--#{$prefix}sidebar-menu-item-active-color) !important;
              .active {
                color: var(
                  --#{$prefix}sidebar-menu-item-active-color
                ) !important;
              }
            }
          }
        }
      }
    }
  }
}

[data-bs-theme="dark"] body[data-layout="vertical"] {
  .logo-dark {
    display: none;
  }
  .logo-light {
    display: block;
  }
}
