//
// Form-Upload
//

/* Dropzone */
.dropzone {
  min-height: 230px;
  border: 2px dashed var(--#{$prefix}border-color);
  background: var(--#{$prefix}secondary-bg);
  border-radius: 6px;

  .dz-message {
    font-size: 24px;
    width: 100%;
  }
}
