//
// toastr.scss
//

/* =============
   Notification
============= */

.toast-container {
  z-index: 1000;
}

#toast-container {
  > div {
    box-shadow: $box-shadow;
    opacity: 1;
    &:hover {
      box-shadow: $box-shadow;
      opacity: 0.9;
    }
  }

  &.toast-top-full-width,
  &.toast-bottom-full-width {
    > div {
      min-width: 96%;
      margin: 4px auto;
    }
  }
}

@each $color, $value in $theme-colors {
  .toast-#{$color} {
    border: 2px solid $value !important;
    background-color: rgba(($value), 0.8) !important;
  }
}

// for error

.toast-error {
  background-color: rgba($danger, 0.8);
  border: 2px solid $danger;
}

.toastr-options {
  padding: 24px;
  background-color: var(--#{$prefix}secondary-bg);
  margin-bottom: 0;
  border: 1px solid var(--#{$prefix}border-color);
}
