#localbodyTableId {
  th {
    text-align: center;
  }
  td:nth-child(1) {
    text-align: center;
  }
  td:nth-child(1),
  td:nth-child(2),
  td:nth-child(3),
  td:nth-child(4) {
    text-align: center;
  }
  td:nth-child(2),
  td:nth-child(3),
  td:nth-child(4) {
    min-width: 100px;
  }
}
