//
// Datepicker
//

[dir="rtl"] {
  .datepicker {
    right: 0 !important;
    left: auto;
  }
}

[dir="rtl"] {
  .bootstrap-timepicker-widget {
    right: 0 !important;
    left: auto;
  }

  .timepicker-orient-top {
    top: $input-height !important;
  }

  .timepicker-orient-bottom {
    top: auto !important;
    bottom: $input-height !important;
  }
}

.datepicker {
  border: 1px solid var(--#{$prefix}border-color);
  padding: 8px;
  z-index: 999 !important;

  table {
    tr {
      th {
        font-weight: 500;
      }
      td {
        &.active,
        &.active:hover,
        .active.disabled,
        &.active.disabled:hover,
        &.today,
        &.today:hover,
        &.today.disabled,
        &.today.disabled:hover,
        &.selected,
        &.selected:hover,
        &.selected.disabled,
        &.selected.disabled:hover,
        span.active.active,
        span.active:hover.active {
          background-color: $primary !important;
          background-image: none;
          box-shadow: none;
          color: $white !important;
        }

        &.day.focused,
        &.day:hover,
        span.focused,
        span:hover {
          background: $light;
        }

        &.new,
        &.old,
        span.new,
        span.old {
          color: $gray-500;
          opacity: 0.6;
        }

        &.range,
        &.range.disabled,
        &.range.disabled:hover,
        &.range:hover {
          background-color: var(--#{$prefix}secondary-bg);
        }
      }
    }
  }
}

.table-condensed {
  > thead > tr > th,
  > tbody > tr > td {
    padding: 7px;
  }
}

.bootstrap-datepicker-inline {
  .datepicker-inline {
    width: auto !important;
    display: inline-block;
  }
}

// DATEPICKER

.datepicker-container {
  border: 1px solid var(--#{$prefix}border-color);
  box-shadow: none;
  background-color: var(--#{$prefix}secondary-bg);

  &.datepicker-inline {
    width: 212px;
  }
}

.datepicker-panel {
  > ul {
    > li {
      background-color: var(--#{$prefix}secondary-bg);
      border-radius: 4px;

      &.picked,
      &.picked:hover {
        background-color: rgba($primary, 0.25);
        color: $primary;
      }

      &.highlighted,
      &.highlighted:hover,
      &:hover {
        background-color: $primary;
        color: $white;
      }

      &.muted,
      &.muted:hover {
        color: $gray-500;
        opacity: 0.6;
      }
    }

    &[data-view="week"] {
      > li {
        font-weight: $font-weight-medium;
      }

      > li,
      > li:hover {
        background-color: var(--#{$prefix}secondary-bg);
      }
    }
  }
}

// flatpickr

.flatpickr-input {
  &[readonly] {
    background-color: var(--#{$prefix}input-bg);
  }
}

.flatpickr-months,
.flatpickr-weekdays {
  background-color: $primary;
}

span.flatpickr-weekday {
  color: $white;
  font-weight: $font-weight-medium;
}

.flatpickr-current-month {
  .flatpickr-monthDropdown-months {
    &:hover {
      background-color: transparent !important;
    }
  }
}

.flatpickr-am-pm,
.numInput,
.numInputWrapper {
  &:focus,
  &:hover {
    background-color: transparent !important;
  }
}

.flatpickr-weekdays {
  height: 36px !important;
  border-bottom: 1px solid var(--#{$prefix}border-color);
}

.flatpickr-day {
  color: var(--#{$prefix}body-color) !important;
  &:hover,
  &:focus {
    background-color: rgba($light, 0.7) !important;
  }
  &.today {
    border-color: $primary !important;
    background-color: rgba($primary, 0.1);
    &:hover,
    &:focus {
      color: var(--#{$prefix}body-color) !important;
      background-color: rgba($primary, 0.15) !important;
    }
  }
  &.selected {
    background-color: $primary !important;
    border-color: $primary !important;
    color: $white !important;
  }

  &.flatpickr-disabled,
  &.flatpickr-disabled:hover,
  &.prevMonthDay,
  &.nextMonthDay,
  &.notAllowed,
  &.notAllowed.prevMonthDay,
  &.notAllowed.nextMonthDay {
    color: rgba(var(--#{$prefix}dark), 0.3) !important;
  }

  &.inRange,
  &.prevMonthDay.inRange,
  &.nextMonthDay.inRange,
  &.today.inRange,
  &.prevMonthDay.today.inRange,
  &.nextMonthDay.today.inRange,
  &:hover,
  &.prevMonthDay:hover,
  &.nextMonthDay:hover,
  &:focus,
  &.prevMonthDay:focus,
  &.nextMonthDay:focus {
    background-color: var(--#{$prefix}tertiary-bg) !important;
    border-color: var(--#{$prefix}border-color) !important;
  }
  &.inRange {
    box-shadow:
      -5px 0 0 $light,
      5px 0 0 $light;
  }
}

.flatpickr-months {
  .flatpickr-prev-month,
  .flatpickr-next-month,
  .flatpickr-month {
    color: rgba($white, 0.9) !important;
    fill: rgba($white, 0.9) !important;
  }
}

.flatpickr-monthDropdown-month {
  color: rgba($black, 0.8);
}
.flatpickr-current-month {
  input.cur-year {
    &[disabled],
    &[disabled]:hover {
      color: rgba($white, 0.9) !important;
    }
  }
}

.flatpickr-time {
  input,
  .flatpickr-time-separator,
  .flatpickr-am-pm {
    color: var(--#{$prefix}body-color) !important;
  }
}

.flatpickr-calendar {
  background-color: var(--#{$prefix}secondary-bg) !important;
  box-shadow:
    1px 0 0 var(--#{$prefix}border-color),
    -1px 0 0 var(--#{$prefix}border-color),
    0 1px 0 var(--#{$prefix}border-color),
    0 -1px 0 var(--#{$prefix}border-color),
    0 3px 13px rgba(0, 0, 0, 0.08) !important;
  &.hasTime {
    .flatpickr-time {
      border-top: 1px solid var(--#{$prefix}border-color) !important;
    }
  }
}

.flatpickr-months {
  border-radius: 5px 5px 0px 0px;
  .flatpickr-prev-month,
  .flatpickr-next-month {
    &:hover {
      svg {
        fill: rgba($white, 0.9) !important;
      }
    }
  }
}

.datepicker-switch {
  background-color: var(--#{$prefix}tertiary-bg);
}

.datepicker {
  table tr td.day:hover,
  table tr td span.focused,
  table tr td span:hover,
  .next:hover,
  .prev:hover,
  .datepicker-switch:hover {
    background-color: var(--#{$prefix}tertiary-bg);
  }
}
