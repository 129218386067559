.eye-icon {
  font-size: 1.2em !important;
  margin-right: 0.5rem !important;
  cursor: pointer;
}

.edit-icon {
  font-size: 1.2em !important;
  cursor: pointer !important;
  margin-right: 0.5rem !important;
}

.delete-icon {
  font-size: 1em !important;
  cursor: pointer !important;
}

.file-icon {
  cursor: pointer !important;
  font-size: 1.5em !important;
  color: black !important;
  margin: 0 0.5rem !important;
}

.image-icon {
  cursor: pointer !important;
  font-size: 1.5em !important;
  color: black !important;
  margin: 0 0.5rem !important;
}

.excel-icon {
  font-size: 1em !important;
  color: green !important;
  cursor: pointer !important;
  margin-right: 0.6em !important;
}

.pdf-icon {
  font-size: 1.2em !important;
  cursor: pointer !important;
  margin-right: 0.5rem !important;
  color: red !important;
}

.video-icon {
  cursor: pointer !important;
  color: #2980b9 !important;
  font-size: 1.2em !important;
  margin-right: 0.5rem !important;
}
.copy-icon {
  cursor: pointer !important;
  color: #2980b9 !important;
  font-size: 1.2em !important;
  margin-right: 0.5rem !important;
}

.generate-icon {
  font-size: 1.2em !important;
  cursor: pointer !important;
  color: #34c38f !important;
  margin-right: 0.5rem !important;
}

.asap-offer-letter-icon {
  font-size: 1.2em !important;
  cursor: pointer !important;
  margin-right: 0.5rem !important;
  color: #34c38f !important;
}

.spinner-icon {
  font-size: 1em !important;
  margin-right: 0.5rem !important;
  cursor: default !important;
}

.check-icon {
  font-size: 1rem;
  color: green;
}

.un-check-icon {
  font-size: 1rem;
  color: #dc3545;
}

.share-icon {
  cursor: pointer;
  margin-right: 0.5rem !important;
  font-size: 1.2em !important;
}

.money-icon {
  cursor: pointer;
  margin-right: 0.5rem !important;
  font-size: 1em !important;
}

.completion-letter-icon {
  font-size: 1.2em !important;
  cursor: pointer !important;
  margin-right: 0.5rem !important;
  color: #1a73e8 !important;
}

.report-icon {
  font-size: 1.2em !important;
  cursor: pointer !important;
  margin-right: 0.5rem !important;
  color: #f4af1d !important;
}

.share-icon {
  font-size: 1em !important;
  cursor: pointer !important;
  margin-right: 0.5rem !important;
}

.download-file-icon {
  font-size: 1.5em !important;
  cursor: pointer !important;
}
